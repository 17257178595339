import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: "hr",
    // lng: "hr",
    backend: {
      request: async (options, url, payload, callback) => {
        try {
          let ret;
          ret = await fetch(url);
          if (!ret.ok) return callback("failed to fetch...");
          const data = await ret.json();
          callback(null, {
            status: 200,
            data: JSON.stringify(data),
          });
        } catch (e) {
          callback(e);
        }
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });
