import React from "react";
import oNama from "../images/onama.jpg";
import { useTranslation } from "react-i18next";
const About = () => {
  const { t } = useTranslation();
  return (
    <div
      id="about"
      className="px-4 -mb-6 md:px-20 pt-40 pb-40  text-black bg-white bg-[url('../public/assets/wavedown.svg')]  bg-no-repeat bg-cover text-center justify-center flex lg:flex-row flex-col"
    >
      <div className=" lg:pr-40  lg:w-[50%] flex flex-col justify-center items-center  text-left  ">
        {" "}
        <h2 className="text-center font-didact text-2xl text-black  font-bold  pb-8">
          {t("Naslovonama")}
        </h2>{" "}
        <p className="leading-10">{t("Tekstonama")}</p>
      </div>
      <div className="lg:w-[50%] pt-10 sm:pt-0 md:pt-20 block lg:block ">
        {" "}
        <img
          src={oNama}
          className="lg:ml-40 md:ml-20 md:w-[80%]  rounded-lg"
          alt="O nama fotografija"
        />
      </div>
    </div>
  );
};

export default About;
