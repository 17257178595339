import React from "react";
import oNama from "../images/gis.jpg";
import { useTranslation } from "react-i18next";
const Gis = () => {
  const { t } = useTranslation();
  return (
    <div
      id="cloudgis"
      className="px-4 md:px-20  pt-40 pb-40 text-black  bg-gradient-to-t from-zinc-200  to-white text-center justify-center flex lg:flex-row flex-col "
    >
      <div className="lg:pr-40  lg:w-[50%] flex flex-col justify-center items-center  text-left  ">
        {" "}
        <h2 className="text-center font-didact text-2xl text-black  font-bold  pb-8">
          CLOUD-GIS
        </h2>{" "}
        <p className="leading-10">{t("Tekstgis")}</p>
      </div>
      <div className="lg:w-[50%] sm:pt-10 block lg:block ">
        {" "}
        <img
          src={oNama}
          className="lg:ml-40 md:ml-20 md:w-[80%]  rounded-lg"
          alt="Cloud gis fotografija"
        />
      </div>
    </div>
  );
};

export default Gis;
