import React from "react";
import Office from "../images/office.jpg";
import Office2 from "../images/office2.jpg";
import Office3 from "../images/office3.jpg";
import Office4 from "../images/office4.jpg";
import { FaPhone } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { useTranslation } from "react-i18next";
const Uredi = () => {
  const { t } = useTranslation();
  //funkcija za import svih fotki iz foldera u polje
  // function importAll(r) {
  //   return r.keys().map(r);
  // }

  // useEffect(() => {
  //   setImages(
  //     importAll(
  //       require.context("../assets/portfolio/", false, /\.(png|jpe?g|svg)$/)
  //     )
  //   );
  // }, []);
  return (
    <div
      id="uredi"
      className="bg-gradient-to-t from-white  to-zinc-200  text-black mb-10 "
    >
      <div className="pt-20">
        <h2 className="text-center text-2xl text-black  font-bold  pb-14">
          {t("Uredi")}
        </h2>
      </div>
      <div className="grid  grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4 place-items-center px-10 md:px-20">
        <div className="max-w-md rounded overflow-hidden pb-5 mb-5 shadow-2xl">
          <img className="w-full" src={Office} alt="GEO-KOM Ured" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{t("Sjediste")} </div>
            <div className="font-bold text-xl mb-5"> Duga Resa</div>
            <ImLocation size={25} className="inline-flex" />
            <hr className="border-black mt-2 mb-2" />
            <p className="text-gray-700 mb-8 text-base ">
              B. J. Jelačića 87, Duga Resa
            </p>
            <p className="text-gray-700 py-1.5 text-base ">
              HR2724000081190266492{" "}
            </p>
            <p className="mb-5">Karlovačka banka</p>
            <p className="text-gray-700 text-base "> 047/801-662</p>
            <hr className="border-black mt-2 mb-2" />
            <FaPhone size={20} className="inline-flex" />
          </div>
        </div>
        <div className="max-w-md  rounded overflow-hidden pb-5 mb-5 shadow-2xl">
          <img className="w-full" src={Office2} alt="GEO-KOM Ured" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{t("Poslovnica")} </div>
            <div className="font-bold text-xl mb-5"> Karlovac</div>
            <ImLocation size={25} className="inline-flex" />
            <hr className="border-black mt-2 mb-2" />
            <p className="text-gray-700 mb-8 text-base ">
              J. Križanića 23, Karlovac
            </p>

            <p className="text-gray-700 text-base pt-20 "> 047/418-275</p>
            <hr className="border-black mt-2 mb-2" />
            <FaPhone size={20} className="inline-flex" />
          </div>
        </div>
        <div className="max-w-md  rounded overflow-hidden pb-5 mb-5 shadow-2xl">
          <img className="w-full" src={Office3} alt="GEO-KOM Ured" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{t("Poslovnica")} </div>
            <div className="font-bold text-xl mb-5"> Slunj</div>
            <ImLocation size={25} className="inline-flex" />
            <hr className="border-black mt-2 mb-2" />
            <p className="text-gray-700 mb-8 text-base ">
              dr. Franje Tuđmana 1, Slunj
            </p>

            <p className="text-gray-700 text-base pt-20 "> 047/202-339</p>
            <hr className="border-black mt-2 mb-2" />
            <FaPhone size={20} className="inline-flex" />
          </div>
        </div>
        <div className="max-w-md rounded overflow-hidden pb-5 mb-5 shadow-2xl">
          <img className="w-full" src={Office4} alt="GEO-KOM Ured" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{t("Podruznica")} </div>
            <div className="font-bold text-xl mb-5"> Novi Marof</div>
            <ImLocation size={25} className="inline-flex" />
            <hr className="border-black mt-2 mb-2" />
            <p className="text-gray-700 mb-8 text-base ">
              Kralja Tomislava 4, Novi Marof
            </p>
            <p className="text-gray-700 py-1.5 text-base ">
              HR2523600001102998463{" "}
            </p>
            <p className="mb-5">Zagrebačka banka</p>
            <p className="text-gray-700 text-base "> 099/431-8133</p>
            <hr className="border-black mt-2 mb-2" />
            <FaPhone size={20} className="inline-flex" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uredi;
