import React from "react";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  const ScrollHandle = () => {
    window.scrollTo(0, 0);
  };
  const scrollTo = (element) => {
    const section = document.querySelector(element);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <div className="bg-zinc-200 text-black">
      <footer class="p-4 rounded-lg shadow px-16 md:px-20 md:py-8 ">
        <div class="sm:flex sm:items-center sm:justify-between">
          <img
            src="./assets/logo.png"
            class="hidden sm:block mr-3 h-12"
            alt="MSpudicDesign Logo"
          />

          <ul class="flex flex-wrap items-center mb-6 text-sm text-black sm:mb-0 ">
            <li>
              <p
                class="mr-4 cursor-pointer hover:underline md:mr-6 "
                onClick={() => {
                  ScrollHandle();
                }}
              >
                {t("Pocetna")}
              </p>
            </li>
            <li>
              <p
                className="mr-4 cursor-pointer hover:underline md:mr-6"
                onClick={() => {
                  scrollTo("#about");
                }}
              >
                {t("Onama")}
              </p>
            </li>
            <li>
              <p
                class="mr-4 cursor-pointer hover:underline md:mr-6 "
                onClick={() => {
                  scrollTo("#skeniranje");
                }}
              >
                {t("Skeniranje")}
              </p>
            </li>
            <li>
              <p
                class="mr-4 cursor-pointer hover:underline md:mr-6 "
                onClick={() => {
                  scrollTo("#cloudgis");
                }}
              >
                {t("Cloudgis")}
              </p>
            </li>
            <li>
              <p
                class="mr-4 cursor-pointer hover:underline md:mr-6 "
                onClick={() => {
                  scrollTo("#uredi");
                }}
              >
                {t("Uredi")}
              </p>
            </li>
          </ul>
        </div>
        <hr class="my-6 border-black sm:mx-auto lg:my-8" />
        <span class="block text-sm text-black sm:text-center ">
          © {new Date().getFullYear() + " " + t("Footercopy")}
        </span>
      </footer>
    </div>
  );
};

export default Footer;
