import React, { useState } from "react";
import { HiX, HiMenu } from "react-icons/hi";
import { FaInstagram, FaLinkedin, FaGithub } from "react-icons/fa";
import { US, HR, DE, BS } from "country-flag-icons/react/3x2";
import { BsGlobe } from "react-icons/bs";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Dropdown } from "flowbite-react";
const Navbar = () => {
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const ScrollHandle = () => {
    window.scrollTo(0, 0);
  };
  const [nav, setNav] = useState(false);
  const handleClick = () => {
    setNav((oldState) => !oldState);
  };
  const scrollTo = (element) => {
    const section = document.querySelector(element);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <div className="w-full h-[80px] z-50 bg-zinc-200 top-0 font-bold sticky drop-shadow-lg">
      <div className="px-4 md:px-20 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <section className="flex items-start">
            {" "}
            <img className="h-16" alt="logo" src="./assets/logo.png" />
          </section>
        </div>
        <div className="flex items-center justify-between ">
          <ul className="hidden select-none text-black  lg:flex">
            <p
              className="hover:text-zinc-500 cursor-pointer"
              onClick={() => {
                ScrollHandle();
              }}
            >
              <li>{t("Pocetna")}</li>
            </p>

            <p
              className="hover:text-zinc-500 cursor-pointer"
              onClick={() => {
                scrollTo("#about");
              }}
            >
              <li>{t("Onama")}</li>
            </p>

            <p
              className="hover:text-zinc-500 cursor-pointer"
              onClick={() => {
                scrollTo("#skeniranje");
              }}
            >
              {" "}
              <li>{t("Skeniranje")}</li>
            </p>
            <p
              className="hover:text-zinc-500 cursor-pointer"
              onClick={() => {
                scrollTo("#cloudgis");
              }}
            >
              {" "}
              <li>{t("Cloudgis")}</li>
            </p>
            <p
              className="hover:text-zinc-500 cursor-pointer"
              onClick={() => {
                scrollTo("#uredi");
              }}
            >
              {" "}
              <li>{t("Uredi")}</li>
            </p>
          </ul>
        </div>
        <div className="select-none">
          <Dropdown class="hidden  lg:flex text-black " label={<BsGlobe />}>
            <Dropdown.Item onClick={() => changeLanguage("hr")} icon={HR}>
              HR
            </Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage("en")} icon={US}>
              EN
            </Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage("de")} icon={DE}>
              DE
            </Dropdown.Item>
          </Dropdown>
        </div>
        {/* <div className="hidden text-black md:flex">
          <button
            className="mr-2 hover:scale-105"
            onClick={() => changeLanguage("hr")}
          >
            <HR style={{ width: 30, height: 30 }} />
          </button>
          <button
            className="mr-2 hover:scale-105"
            onClick={() => changeLanguage("en")}
            marginLeft={2}
          >
            <US style={{ width: 30, height: 30 }} />
          </button>
          <button
            className=" hover:scale-105"
            onClick={() => changeLanguage("de")}
            marginLeft={2}
          >
            <DE style={{ width: 30, height: 30 }} />
          </button>
        </div> */}

        <div className="lg:hidden select-none text-black" onClick={handleClick}>
          {!nav ? (
            <HiMenu className="w-6 text-black " />
          ) : (
            <HiX className="w-6" />
          )}
        </div>
      </div>
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute bg-zinc-200 select-none text-black w-full px-12"
        }
      >
        {" "}
        <li
          onClick={() => {
            handleClick();
            ScrollHandle();
          }}
          className="border-b-2 hover:text-zinc-500 border-zinc-300 w-full"
        >
          {t("Pocetna")}
        </li>
        <li
          onClick={() => {
            handleClick();

            scrollTo("#about");
          }}
          className="border-b-2 hover:text-zinc-500 border-zinc-300 w-full"
        >
          {t("Onama")}
        </li>
        <li
          onClick={() => {
            handleClick();

            scrollTo("#skeniranje");
          }}
          className="border-b-2 hover:text-zinc-500 border-zinc-300 w-full"
        >
          {t("Skeniranje")}
        </li>
        <li
          onClick={() => {
            handleClick();

            scrollTo("#cloudgis");
          }}
          className="border-b-2 hover:text-zinc-500 border-zinc-300 w-full"
        >
          {t("Cloudgis")}
        </li>
        <li
          onClick={() => {
            handleClick();

            scrollTo("#uredi");
          }}
          className="border-b-2 hover:text-zinc-500 border-zinc-300 w-full"
        >
          {t("Uredi")}
        </li>
        <div className="mt-5">
          {" "}
          <button
            className="mr-5 hover:scale-105"
            onClick={() => {
              handleClick();
              changeLanguage("hr");
            }}
          >
            <HR style={{ width: 30, height: 30 }} />
          </button>
          <button
            className="mr-5 hover:scale-105"
            onClick={() => {
              handleClick();
              changeLanguage("en");
            }}
            marginLeft={2}
          >
            <US style={{ width: 30, height: 30 }} />
          </button>
          <button
            className=" hover:scale-105"
            onClick={() => {
              handleClick();
              changeLanguage("de");
            }}
            marginLeft={2}
          >
            <DE style={{ width: 30, height: 30 }} />
          </button>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
