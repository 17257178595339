import React from "react";
import { Carousel } from "flowbite-react/lib/esm/components";
import Image1 from "../images/galerija/DSC_0748.jpg";
import Image2 from "../images/galerija/DSC_0782.jpg";
import Image3 from "../images/galerija/DSC_0786.jpg";
import Image4 from "../images/galerija/DSC_0723.jpg";
import Image5 from "../images/galerija/DSC_0808.jpg";
import Image6 from "../images/galerija/DSC_0000.jpg";
const Gallery = () => {
  return (
    <div className="lg:px-80 px-0 h-[200px] md:px-20 xl:h-[500px] sm:h-[500px] md:h-[500px] lg:h-[1000px] 2xl:h-[1200px] bg-[#E6E6E9]">
      <Carousel style={{}} slideInterval={5000} indicators={false}>
        <img src={Image1} alt="..." />
        <img src={Image2} alt="..." />
        <img src={Image3} alt="..." />
        <img src={Image4} alt="..." />
        <img src={Image5} alt="..." />
        <img src={Image6} alt="..." />
      </Carousel>
    </div>
  );
};

export default Gallery;
