import React from "react";
import oNama from "../images/onama.jpg";
import Video from "../images/hero.gif";
import { useTranslation } from "react-i18next";
const Skeniranje = () => {
  const { t } = useTranslation();
  return (
    <div
      id="skeniranje"
      className="px-4 md:px-20 pt-40 pb-40 text-black bg-white bg-[url('../public/assets/wave.svg')] bg-no-repeat bg-cover text-center justify-center flex lg:flex-row flex-col "
    >
      <div className="lg:w-[50%] flex justify-center items-center pb-10 lg:pb-0 lg:block ">
        {" "}
        <img src={Video} alt="3D skeniranje gif" />
      </div>
      <div className="lg:pl-40 flex flex-col justify-center items-center  lg:w-[50%]  text-left  ">
        {" "}
        <h2 className="text-center font-didact text-2xl text-black  font-bold  pb-8">
          {t("Naslovskeniranje")}
        </h2>{" "}
        <p className="leading-10 ">{t("Tekstskeniranje")}</p>
      </div>
    </div>
  );
};

export default Skeniranje;
