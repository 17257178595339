import React from "react";
import Logo from "../images/logo.png";
import Video from "../images/hero.mp4";
import { useTranslation } from "react-i18next";
const Hero = () => {
  const { t } = useTranslation();
  return (
    <div id="home">
      <header class="relative h-screen bg-black bg-center md:bg-left  bg-cover bg-[url('../public/assets/hero.jpg')]  overflow-hidden">
        <div class="z-10  p-5 lg:p-20 flex flex-col  items-center  lg:items-start md:w-1/2  h-screen justify-center text-black  bg-zinc-100 bg-opacity-70 lg:bg-opacity-30 ">
          <h1 className="uppercase text-5xl lg:text-6xl font-didact font-bold  text-black">
            GEO-KOM
          </h1>
          <span class=" w-60 lg:w-[470px] p-[0.2px] bg-black lg:full mt-4 mb-10"></span>
          <h4 className="font-raleway text-md font-bold md:text-lg lg:text-2xl">
            {" "}
            {t("Teksthero")}
          </h4>
        </div>
        {/* <video
          autoPlay
          loop
          muted
          class="absolute bg-white  w-auto min-w-full min-h-full max-w-none"
        >
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
      </header>
    </div>
  );
};

export default Hero;
