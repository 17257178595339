import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import PuffLoader from "react-spinners/PuffLoader";

const root = ReactDOM.createRoot(document.getElementById("root"));
const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#A8DCFA",
};
root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div>
          {" "}
          <PuffLoader color={"#A8DCFA"} cssOverride={style} size={150} />
          <h1 style={style}>Loading..</h1>
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
