import { useEffect } from "react";
import "./App.css";
import Hero from "./components/Hero.jsx";
import Uredi from "./components/Uredi";
import Navbar from "./components/Nav";
import About from "./components/About";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Geodezija from "./components/Geodezija.jsx";
import Skeniranje from "./components/Skeniranje";
import Gis from "./components/Gis";
import { useTranslation } from "react-i18next";
function App() {
  useEffect(() => {
    document.title = "GEO-KOM |  Geodetska firma";
  }, []);
  const { i18n } = useTranslation();
  if (!i18n.resolvedLanguage) return "failed to load translations";
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <About />
      {/* <Geodezija /> */}

      <Skeniranje />
      <Gis />
      <Gallery />
      <Uredi />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
