import React, { useEffect, useState } from "react";
import { FaAngleUp } from "react-icons/fa";
const ScrollToTop = () => {
  const [scrollCheck, setScrollCheck] = useState();
  const [scrollPosition, setScrollPosition] = useState();
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (scrollPosition >= 180) {
      setScrollCheck("block");
    } else {
      setScrollCheck("hidden");
    }
  }, [scrollPosition]);
  const ScrollHandle = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      id="backToTop"
      onClick={ScrollHandle}
      className={`${scrollCheck} fixed z-50 bottom-0 right-0 hover:bg-zinc-300/50 bg-zinc-400/50 cursor-pointer rounded-md px-3 py-3 mr-4 mb-6`}
    >
      <FaAngleUp className="text-white " />
    </div>
  );
};

export default ScrollToTop;
